import React, { useContext } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import {
  CartContext,
  useGoToCheckout,
  useUpdateItemInCart,
  useRemoveItemFromCart,
  useToggleCart,
} from '../../contexts/CartContext';
import CartItem from '../CartItem/CartItem';
import Close from '../../icons/Close';
import Box from '../../icons/Box';

import Spinner from '../../icons/Spinner';
import styles from './styles.module.scss';
import { formatCurrency } from '../../utils';

const CartDrawer = ({ isOpen }) => {
  const {
    cart: {
      //checkout: { lineItems, totalPrice },
      checkout2: { lineItems, totalPrice },
      //checkout2,
    },
    cart,
  } = useContext(CartContext);

  const toggleCart = useToggleCart();
  const goToCheckout = useGoToCheckout();

  const data = useStaticQuery(graphql`
    query CartQuery {
      allShopifyProduct {
        edges {
          node {
            shopifyId
            storefrontId
            vendor
            title
            handle
            hasOutOfStockVariants
            priceRangeV2 {
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            totalVariants
            variants {
              shopifyId
              storefrontId
              presentmentPrices {
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  `);

  const updateItem = useUpdateItemInCart();
  const removeItem = useRemoveItemFromCart();

  // pass some extra attributes to CartItem, for linking back to product
  const getExtraAttributes = id => {
    const vendor = data.allShopifyProduct.edges.reduce(
      (
        result,
        {
          node: {
            hasOutOfStockVariants,
            handle,
            vendor,
            variants,
            totalVariants,
          },
        }
      ) => {
        const match = variants.find(variant => variant.storefrontId === id);

        if (match) {
          result.handle = handle;
          result.vendor = vendor;
          result.hasOutOfStockVariants = hasOutOfStockVariants;
          result.totalVariants = totalVariants;
        }
        return result;
      },
      {}
    );
    return vendor;
  };

  // console.log('===lineItems');
  // console.log(lineItems);

  const cartItems = lineItems.map(lineItem => {
    return {
      ...lineItem,
      ...getExtraAttributes(lineItem.variant.id),
    };
  });

  return (
    <div className={cx(styles.base, { [styles.isOpen]: isOpen })}>
      <div className={styles.header}>
        <h4>Your cart</h4>
        <button type="button" onClick={() => toggleCart(false)}>
          <Close />
        </button>
      </div>
      <div className={cx('container', styles.content)}>
        <div className={styles.cart}>
          {lineItems.length ? (
            <>
              <ul>
                {cartItems.map(item => (
                  <CartItem
                    key={item.id}
                    item={item}
                    remove={removeItem}
                    update={updateItem}
                  />
                ))}
              </ul>
              <div className={styles.total}>
                <span>Total</span>&nbsp;&nbsp;
                <span>
                  {formatCurrency(
                    totalPrice.amount,
                    totalPrice.currencyCode,
                    true
                  )}
                </span>
              </div>
              <div className="d-flex d-flex-end">
                <button
                  className="btn btn--medium btn--secondary space-right"
                  onClick={() => toggleCart(false)}
                >
                  Continue shopping
                </button>
                <button className="btn btn--medium" onClick={goToCheckout}>
                  Go To Checkout
                </button>
              </div>
            </>
          ) : (
            <div>
              <div className={styles.emptyCart}>
                <Box />
                <p>Your cart is empty</p>
                <hr />
                <div className={styles.actions}>
                  <Link to="/subscribe" className="btn btn--medium space-y">
                    Add a subscription
                  </Link>{' '}
                  <br />
                  or <br />
                  check out the{' '}
                  <Link to="/shop">
                    <span className={styles.maoboxShop}>
                      MāoBox<span>Shop</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;
