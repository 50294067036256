import React, { useContext } from 'react';
import cx from 'classnames';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { renderNodeConfig } from '../utils';
import { CartContext, useToggleCart } from '../contexts/CartContext';
import Cart from '../icons/Cart';
import MenuIcon from '../icons/Menu';
import CloseIcon from '../icons/Close';
import styles from './navbar.module.scss';

const NavBar = () => {
  const [isActive, setIsActive] = React.useState(false);
  const toggleCart = useToggleCart();

  const {
    contentfulHeader: {
      logo: {
        file: { url },
      },
      navigation,
      announcement = null,
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulHeader(contentful_id: { eq: "7Fke7MbTZuGKvXou8uJ1u7" }) {
        id
        contentful_id
        title
        logo {
          contentful_id
          file {
            url
          }
        }
        navigation {
          ... on ContentfulPage {
            title
            slug
            contentful_id
          }
          ... on ContentfulHowItWorksPage {
            title
            slug
            contentful_id
          }
          ... on ContentfulFaqListingPage {
            title
            slug
            contentful_id
          }
        }
        announcement
      }
    }
  `);

  const {
    cart: {
      checkout,
      checkout2: { lineItems },
      isDrawerOpen,
    },
  } = useContext(CartContext);

  //console.log(checkout2);

  return (
    <div className={styles.base}>
      {announcement && (
        <div className={styles.bar}>
          <span>{announcement}</span>
        </div>
      )}
      <nav className={cx(styles.navbar)}>
        {isActive && (
          <div className="overlay" onClick={() => setIsActive(false)} />
        )}
        <div
          className={cx(styles.inner, 'container', 'd-flex', 'd-flex-between')}
        >
          <Link to="/" className="unstyled">
            <img className={styles.logo} src={url} alt="MāoBox logo" />
          </Link>
          <div className="d-flex">
            <button
              type="button"
              className="mobile-only"
              onClick={() => {
                setIsActive(true);
              }}
            >
              <MenuIcon />
            </button>
            <nav className={cx(styles.nav, { [styles.active]: isActive })}>
              <ul className={styles.navList}>
                {navigation.map(({ contentful_id, title, slug }) => (
                  <li key={contentful_id} className={styles.navItem}>
                    <Link
                      to={slug}
                      className={styles.navLink}
                      activeClassName={styles.active}
                    >
                      {slug.includes('shop') ? (
                        <>
                          MāoBox<span className={styles.shop}>Shop</span>
                        </>
                      ) : (
                        title
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
              <button
                className={cx(styles.close, 'mobile-only')}
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <CloseIcon />
              </button>
            </nav>
            <button
              className={cx(styles.cart, 'desktop-only unstyled')}
              onClick={() => toggleCart(!isDrawerOpen)}
            >
              <Cart />
              {!!lineItems.length && <span>{lineItems.length}</span>}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
